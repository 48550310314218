import {useState} from "react";

export function RadioBoxes({options, minLabel, maxLabel, selectedIndex, setSelectedIndex}) {
    return (
        <div className={"flex flex-col justify-center items-center bg-black w-full h-52"}>
            <div className={'flex flex-row m-auto  h-full p-3 justify-center text-center text-xl text-white w-full'}>
                <p className={'text-lg text-left self-end'}>{minLabel}</p>
                {options.map((option, index) => {
                    return (
                        <div onClick={() => setSelectedIndex(index)} key={index}
                             className={`flex self-start ${selectedIndex === index ? "bg-gray-600" : "bg-transparent"} cursor-pointer hover:bg-gray-600 m-auto w-fit rounded-xl flex-col h-1/2 p-4 px-6 justify-center text-left items-center text-xl text-white border-2 border-gray-600`}>
                            <p className={'text-lg'}>{option}</p>
                        </div>
                    )
                })}
                <p className={'text-lg text-right self-end'}>{maxLabel}</p>
            </div>
        </div>
    );
}