import ReactAudioPlayer from 'react-audio-player';

const Player = ({url, tab}) => (
  <div className='w-full flex flex-col justify-start items-center pt-3'>
    <p className='pl-4 w-10/12 text-left text-sm text-mozn-text pb-2'>{tab}</p>
    <ReactAudioPlayer
      src={url}
      controls
      className='h-10 w-10/12'
    />
  </div>
);

export default Player