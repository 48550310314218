import {RadioBoxes} from "../../components/radioBoxes";
import {useEffect, useState} from "react";
import {sendRequestWithToken} from "../../auth/security";
import {auth} from "../../auth/firebase";



export function SurveyModal({showModal, setShowModal, setIsCompleted, mode, type, sessionId}) {
    const [selectedSummaryIndex, setSelectedSummaryIndex] = useState(-1);
    const [selectedTranscriptionIndex,  setSelectedTranscriptionIndex] = useState(-1);
    const [showThankYou, setShowThankYou] = useState(false);

    useEffect(() => {
        setSelectedSummaryIndex(-1);
        setSelectedTranscriptionIndex(-1);
        setShowThankYou(false);
    }, [showModal]);

    const submitFeedback = () => {
        if (selectedSummaryIndex === -1 || selectedTranscriptionIndex === -1) {
            alert('Please select a value for both questions');
            return false;
        }
        console.log(selectedSummaryIndex);
        console.log(selectedTranscriptionIndex);
        let FeedBackJson = {
            "sessionId": String(sessionId),
            "mode": String(mode),
            "type": String(type),
            "questions": [
                {
                    "question": "How well did the summary capture key points and actions?",
                    "answer": selectedSummaryIndex + 1
                },
                {
                    "question": "How satisfied are you with the accuracy and completeness of the transcribed words?",
                    "answer": selectedTranscriptionIndex + 1
                }
            ]
        };

        sendRequestWithToken(`${process.env.REACT_APP_BASE_URL}/survey`, 'post', FeedBackJson)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
        return true;
    }

    if (showThankYou) {
        return (
            <div
                className={`m-auto absolute flex flex-col justify-between items-center bg-black border-gray-700 border-2 p-10 rounded-lg w-full h-96 ${showModal ? "visible" : "hidden"}`}>
                <div className={"flex flex-row justify-center items-center w-full border-b-2 pb-4 border-gray-600"}>
                    <h1 className={"flex flex-row justify-center text-2xl items-center text-center font-bold text-white w-fit"}>Satisfaction
                        Survey</h1>
                    <div className={"absolute h-full w-fit flex-col justify-center content-center right-12"}>
                        <svg className={'cursor-pointer'} onClick={() => {
                            setShowModal(false);
                            setIsCompleted(true);
                        }} width="15" height="15" viewBox="0 0 15 15" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 1L1 14M1 1L14 14" stroke="white" strokeOpacity="0.3" strokeWidth="2"
                                  strokeLinecap="round"/>
                        </svg>
                    </div>
                </div>

                <svg width="93" height="64" viewBox="0 0 93 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M7.59465 2.55831C7.59465 1.50386 8.43784 0.647757 9.47046 0.647757C10.5031 0.647757 11.3415 1.5039 11.3415 2.55831V6.331C11.3415 7.39028 10.5031 8.24639 9.47046 8.24639C8.4378 8.24639 7.59465 7.39025 7.59465 6.331V2.55831ZM0.35672 9.94892C0.35672 8.89447 1.19517 8.03837 2.23253 8.03837H5.92728C6.95994 8.03837 7.79837 8.89451 7.79837 9.94892C7.79837 11.0082 6.9599 11.8643 5.92728 11.8643H2.23253C1.19514 11.8643 0.35672 11.0082 0.35672 9.94892ZM11.1424 9.95375C11.1424 8.89448 11.9809 8.03837 13.0135 8.03837H16.7083C17.7457 8.03837 18.5841 8.89451 18.5841 9.94892C18.5841 11.0082 17.7456 11.8643 16.7083 11.8643H13.0135C11.9808 11.8643 11.1424 11.0082 11.1424 9.95375ZM7.5944 13.572C7.5944 12.5128 8.43759 11.6567 9.47021 11.6567C10.5029 11.6567 11.3413 12.5128 11.3413 13.572V17.3447C11.3413 18.3992 10.5028 19.2553 9.47021 19.2553C8.43756 19.2553 7.5944 18.3991 7.5944 17.3447V13.572ZM81.3236 47.3033C81.3236 46.244 82.1621 45.3879 83.1947 45.3879C84.2321 45.3879 85.0705 46.244 85.0705 47.3033V51.076C85.0705 52.1304 84.2321 52.9865 83.1947 52.9865C82.1621 52.9865 81.3236 52.1304 81.3236 51.076V47.3033ZM74.0857 54.6939C74.0857 53.6394 74.9242 52.7833 75.9568 52.7833H79.6515C80.6842 52.7833 81.5226 53.6395 81.5226 54.6939C81.5226 55.7483 80.6842 56.6093 79.6515 56.6093H75.9568C74.9241 56.6093 74.0857 55.7483 74.0857 54.6939ZM84.8667 54.6939C84.8667 53.6394 85.7051 52.7833 86.7378 52.7833H90.4325C91.4699 52.7833 92.3083 53.6395 92.3083 54.6939C92.3083 55.7483 91.4699 56.6093 90.4325 56.6093H86.7378C85.7051 56.6093 84.8667 55.7483 84.8667 54.6939ZM81.3234 58.3119C81.3234 57.2575 82.1619 56.4014 83.1945 56.4014C84.2319 56.4014 85.0703 57.2575 85.0703 58.3119V62.0846C85.0703 63.1439 84.2318 64 83.1945 64C82.1618 64 81.3234 63.1439 81.3234 62.0846V58.3119Z"
                          fill="white"/>
                    <path
                        d="M47.866 7.94166H47.8612V7.9465H47.8565C46.497 7.9465 45.3696 8.96705 45.1802 10.2971V10.3068C42.8024 20.3236 35.0339 28.1976 25.1864 30.5391H25.177C23.9833 30.8293 23.1023 31.9224 23.1023 33.2284C23.1023 34.5875 24.0639 35.7193 25.3286 35.9514L25.3712 35.9611C35.2096 38.3505 42.8876 46.244 45.2134 56.2949V56.2998C45.4644 57.5621 46.5587 58.515 47.8707 58.515C49.1639 58.515 50.2438 57.5961 50.5092 56.3578L50.5139 56.3482C52.8207 46.2781 60.5655 38.3459 70.4131 35.9512C71.6779 35.7142 72.6299 34.5823 72.6299 33.2233C72.6299 31.8641 71.6731 30.7372 70.4084 30.5051H70.3942C60.6079 28.1109 52.896 20.2512 50.5471 10.2579V10.253C50.3387 8.93262 49.2159 7.94166 47.866 7.94166Z"
                        fill="white"/>
                </svg>
                <p className={"flex flex-row p-3 justify-center text-center items-center text-xl text-white w-full"}>Thank
                    you for helping us improve our services!</p>
                <button onClick={() => {
                    setShowModal(false);
                    setIsCompleted(true);
                }}
                        style={{'background': 'linear-gradient(256.3deg, rgba(35, 213, 190, 0.3) 0%, rgba(131, 93, 249, 0.3) 101.45%)'}}
                        className='w-1/3 min-h-fit text-white text-xl h-14 rounded-xl border-2 border-gray-300'>Close
                    Survey
                </button>
            </div>
        );
    }

    return (
        <div
            className={`m-auto absolute flex flex-col justify-center items-center bg-black border-gray-700 border-2 p-10 rounded-lg w-fit h-fit ${showModal ? "visible" : "hidden"}`}>
            <div className={"flex flex-row justify-center items-center w-full border-b-2 pb-4 border-gray-600"}>
                <h1 className={"flex flex-row justify-center text-2xl items-center text-center font-bold text-white w-fit"}>Satisfaction
                    Survey</h1>
                <div className={"absolute h-full w-fit flex-col justify-center content-center right-12"}>
                    <svg className={'cursor-pointer'} onClick={() => setShowModal(false)} width="15" height="15" viewBox="0 0 15 15" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 1L1 14M1 1L14 14" stroke="white" strokeOpacity="0.3" strokeWidth="2"
                          strokeLinecap="round"/>
                </svg>
                </div>
            </div>
            <p className={"flex flex-row p-3 justify-start text-left items-center text-xl text-white w-full"}>How well
                did the summary capture key points and actions?</p>
            <RadioBoxes options={['1', '2', '3', '4', '5']} maxLabel={'Very Satisfied'} minLabel={"Not Satisfied"}
                        selectedIndex={selectedSummaryIndex} setSelectedIndex={(val) => setSelectedSummaryIndex(val)}/>
            <p className={"flex flex-row p-3 justify-start text-left items-center text-xl text-white w-full"}>How
                satisfied
                are you with the accuracy and completeness of the transcribed words?</p>
            <RadioBoxes options={['1', '2', '3', '4', '5']} maxLabel={'Very Satisfied'} minLabel={"Not Satisfied"}
                        selectedIndex={selectedTranscriptionIndex}
                        setSelectedIndex={(val) => setSelectedTranscriptionIndex(val)}/>
            <button onClick={() => {
                if (!submitFeedback()){
                    return;
                }
                setShowThankYou(true);
            }}
                    style={{'background': 'linear-gradient(256.3deg, rgba(35, 213, 190, 0.3) 0%, rgba(131, 93, 249, 0.3) 101.45%)'}}
                    className='w-1/3 min-h-fit text-white text-xl h-14 rounded-xl border-2 border-gray-300'>Submit
                Feedback
            </button>
        </div>
    );
}