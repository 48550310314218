import Recorder from "./recorder";
import Header from "./header";
import StatusAndContent from "./statusAndContent";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useState, useEffect } from "react";
import {useSignedInStatus} from "../../auth/security";


const Home = () => {

  const steps = {
    dictation: {
      1 :{
        name: 'Dictation',
        type: 'primary',
        defaultIcon: '',
        data: {
          transcript: null,
          summary: null,
          recommendedQuestions: null,
          diagnoses: null,
          actionItems: null,
        },
        recordingStatus: 'idle',
        blob: null,
        statusUpdate: 'not_recording',
        stepStatus: 'not_current',
        canReset: true,
        canRerecord: true
      },
    },
    normal: {
      1 :{
        name: 'P/D Conversation',
        type: 'primary',
        defaultIcon: '',
        data: {
          transcript: null,
          summary: null,
          recommendedQuestions: null,
          diagnoses: null,
          actionItems: null,
        },
        recordingStatus: 'idle',
        blob: null,
        statusUpdate: 'not_recording',
        stepStatus: 'current',
        canReset: false,
        canRerecord: false
      },
      2 :{
        name: 'Action Items',
        type: 'secondary',
        defaultIcon: '',
        data: {
          transcript: null,
          summary: null,
          recommendedQuestions: null,
          diagnoses: null,
          actionItems: null,
        },
        recordingStatus: 'idle',
        blob: null,
        statusUpdate: 'not_recording',
        stepStatus: 'not_current',
        canReset: true,
        canRerecord: true
      },
    }
  }
  const [status, setStatus] = useState("not_recording");
  const [patientRecorded, setPatientRecorded] = useState(false);
  const [actionRecorded, setActionRecorded] = useState(false);
  const [mode , setMode] = useState('dictation')
  const [tabs, setTabs] = useState(steps[mode])
  const [maxTabs, setMaxTabs] = useState(Object.keys(steps[mode]).length)
  const [currentTab, setCurrentTab] = useState(1)
  const [showModal, setShowModal] = useState(false);
  const [surveyType, setSurveyType] = useState('dictation');

  const [startRecord, setStartRecord] = useState({})

  const isSignedIn = useSignedInStatus();


  useEffect(() => {
    let sessionId = sessionStorage.getItem('sessionId')
    if (!sessionId) {
      sessionStorage.setItem('sessionId', String(Math.floor(Date.now() / 1000)))
    }
  }, []);

  const WS_URL = `${process.env.REACT_APP_STREAM_URL}/ws?sessionId=${sessionStorage.getItem('sessionId')}`
  const { lastJsonMessage } = useWebSocket(WS_URL, {
    share: false,
    shouldReconnect: () => true,
  });

  useEffect(() => {
    console.log(`Got a new message: ${lastJsonMessage}`);
  }, [lastJsonMessage]);

  useEffect(() => {
    console.log('tabs', tabs)
  }, [tabs]);

  useEffect(() => {
    updateTab(currentTab, { stepStatus: 'current' });
  }, [currentTab]);

  useEffect(() => {
    setTabs(steps[mode])
    setCurrentTab(1)
    setMaxTabs(Object.keys(steps[mode]).length)
  }, [mode]);

  const handleStartRecord = (startRecordFn) => {
    setStartRecord(startRecordFn);
  }

  const updateTab = (tabId, newTabState) => {
    setTabs((prevTabs) => ({
      ...prevTabs,
      [tabId]: {
        ...prevTabs[tabId],
        ...newTabState,
      },
    }));

  };

  const handleStatus = (status) => {
    setStatus(status);
    updateTab(currentTab, { recordingStatus: status });
  };

  const handleTabs = (targetTab) => {
    updateTab(currentTab, { stepStatus: 'not_current', statusUpdate: 'done' });
    setCurrentTab((prevTab) => (prevTab+1 > maxTabs) ? maxTabs : (prevTab+1) )
  };

  const handleRecordedStatus = (tab, reset = false) => {
    updateTab(currentTab, { statusUpdate: 'done' });
    if (reset) {
      setTabs(steps[mode])
      setCurrentTab(1)
    }
  };

  return (
    <div
      className="h-full w-full p-10 bg-cover bg-no-repeat"
      style={{ backgroundImage: "url('bg.png')" }}
    >
      <Header currentTab={currentTab} tabs={tabs} handleRecordedStatus={handleRecordedStatus}/>
      <div className="flex h-[calc(100%-5rem)] w-full">
        <Recorder
          mode={mode}
          setMode={setMode}
          updateTab={updateTab}
          currentTab={currentTab}
          tabs={tabs}
          handleStatus={handleStatus}
          patientRecorded={patientRecorded}
          actionRecorded={actionRecorded}
          handleTabs={handleTabs}
          handleStartRecord={handleStartRecord}
          setShowModal={setShowModal}
          setSurveyType={setSurveyType}
        />
        <StatusAndContent
          updateTab={updateTab}
          currentTab={currentTab}
          tabs={tabs}
          status={status}
          message={lastJsonMessage}
          handleTabs={handleTabs}
          handleRecordedStatus={handleRecordedStatus}
          startRecord={startRecord}
          mode={mode}
          surveyType={surveyType}
          sessionId={sessionStorage.getItem('sessionId')}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      </div>
    </div>
  );
};

export default Home;
