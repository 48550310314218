import logo from './logo.svg';
import Home from './app/home/home'
import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Login from "./app/login/login";
import Signup from "./app/signup/signup";

function App() {
  return (
      <Router>
          <Routes>
             <Route path={"/"} element={<Home />} />
              <Route path={"/login"} element={<Login />} />
              <Route path={'/signup'} element={<Signup />}  />
          </Routes>
      </Router>
  );
}

export default App;
