import axios from "axios";
import {auth} from "./firebase";
import {useEffect, useState} from "react";
import {onAuthStateChanged} from "firebase/auth";
import {useNavigate} from "react-router-dom";

export async function sendRequestWithToken(url, method, body, contentType = 'application/json') {
    try {
        const token = await auth.currentUser.getIdToken(true);
        return axios({
            url: url,
            method: method,
            data: JSON.stringify(body),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': contentType
            },
        });
    } catch (e) {
        console.log(e);
    }
}

export function useSignedInStatus() {
    const [isSignedIn, setIsSignedIn] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsSignedIn(true);
            } else {
                setIsSignedIn(false);
                navigate('/login');
            }
        });
    }, []);

    return isSignedIn;
}