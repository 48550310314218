import {GlobalLayout} from "../../components/globalLayout";
import {useState} from "react";
import {auth} from "../../auth/firebase";
import {signInWithEmailAndPassword} from "firebase/auth";
import {useNavigate} from "react-router-dom";
export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const handleSubmit = async () => {
        if (email.length === 0 || password.length === 0) {
            alert('Please fill in all fields');
        } else {
            await signInWithEmailAndPassword(auth, email, password).then(async user => {
                const userToken = await user.user.getIdToken();
                navigate('/');
            }).catch((error) => {
                console.log(error);
                alert('Invalid email or password');
            });
        }
    }

    return (<GlobalLayout>
            <form className={'flex flex-col justify-start items-center w-1/4 h-full'}>
            <h1
                style={{background: "linear-gradient(112.95deg, #23D5BE 10.17%, #835DF9 93.02%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontSize: "2rem",
                    fontWeight: "bold",
                    textAlign: "center",
                marginTop: "10rem",}}
            >
                Welcome Back
            </h1>
                <label className={'text-left text-sm text-mozn-text w-full mt-6'} htmlFor="email">Email Address</label>
                <input className={'w-full p-2 text-base border-mozn-border border-[1px] bg-mozn-bg text-mozn-text'} placeholder={'Email Address'} value={email} onChange={(e) => setEmail(e.target.value)} type="email" autoComplete={"off"} />
                <label className={'text-left text-md text-mozn-text w-full mt-6'}  htmlFor="password">Password</label>
                <input className={'w-full p-2 text-base border-mozn-border border-[1px] bg-mozn-bg text-mozn-text'} placeholder={'Password'} value={password} onChange={(e) => setPassword(e.target.value)} type="password" autoComplete={"off"} />
                <p className={'text-center hover:text-white cursor-pointer text-lg font-bold text-mozn-text w-full mt-6'} >Forgot Password?</p>
                <button className={'mt-6 w-full text-mozn-text p-2'} style={{
                    background: "linear-gradient(256.3deg, rgba(35, 213, 190, 0.3) 0%, rgba(131, 93, 249, 0.3) 101.45%)",
                    borderColor: "linear-gradient(256.3deg, rgba(35, 213, 190, 0.3) 0%, rgba(131, 93, 249, 0.3) 101.45%)",
                    border: "1px solid",
                    borderRadius: "5px",
                }} type="submit" onClick={async (e) => {
                    e.preventDefault();
                    await handleSubmit();
                }}>Login</button>
            </form>
        </GlobalLayout>
    );
}